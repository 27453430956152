import React from "react";
import AppModuleHeader from "../common/AppModuleHeader";
import classNames from "./AuditOfAuditsPage.module.scss";
import AuditOfAudits from "./AuditOfAudits";

export const AuditOfAuditsPage = () => (
  <div className={`${classNames.healthDashboardRoot} ${classNames.appModuleContent}`}>
    <AppModuleHeader headerTextOverride="Audit Of Audits" />
    <AuditOfAudits showOnboardStatus={true} />
  </div>
);

export default AuditOfAuditsPage;
